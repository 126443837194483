import * as actionTypes from '../actions/actionTypes';
//import workflowData from './Separates/columnsWorkflow';

const initialState = {
    workflowColumns: [],
    workflowOrder: [],
    status: false
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {


        case actionTypes.GET_WORKFLOW_COLUMNS:
 
            return {
                ...state,
                workflowColumns: action.workflowColumns,
                workflowOrder: action.workflowOrder,
                status: action.status
        }
        case actionTypes.CHANGE_WORKFLOW_STATUS:
            return {
                ...state,
                status: false
                };
        case actionTypes.CHANGE_WORKFLOW_COLUMNS_DATA:

            return {
                ...state,
                workflowColumns: action.workflowColumns
        }

        case actionTypes.MOVE_WORKFLOW_TASK:

            return {
                ...state,
                workflowColumns: action.data
                };

        case actionTypes.MOVE_WORKFLOW_ORDER:
 
            return {
                ...state,
                workflowOrder: action.data     
        }

        case actionTypes.GET_NEW_WORKFLOW_COLUMN:
            let copyCols = JSON.parse(JSON.stringify(state.workflowColumns));
            let copyOrder = JSON.parse(JSON.stringify(state.workflowOrder));
            copyCols.push(action.data)
            copyOrder.push(action.data.unique_id)
 
            return {
                ...state,
                workflowColumns: copyCols,
                workflowOrder: copyOrder    
        }
        case actionTypes.DELETE_WORKFLOW_COLUMN:
            let copyCols1 = JSON.parse(JSON.stringify(state.workflowColumns));
            let deletedCol = copyCols1.filter(gr => gr.id === action.id)[0];
            let deletedColInd = copyCols1.indexOf(deletedCol);
            copyCols1.splice(deletedColInd, 1)

            let copyOrder1 = JSON.parse(JSON.stringify(state.workflowOrder));
            let index = copyOrder1.indexOf(action.unique_id);
            copyOrder1.splice(index, 1);
 
            return {
                ...state,
                workflowColumns: copyCols1,
                workflowOrder: copyOrder1   
        }
        case actionTypes.ADD_ROW_ID_TO_WORKFLOW_COLUMN:
            let copyCols4 = JSON.parse(JSON.stringify(state.workflowColumns));
            let selectedCol = copyCols4.filter(gr => gr.id === action.colId)[0];
            let selectedColInd = copyCols4.indexOf(selectedCol);
            copyCols4[selectedColInd].task_ids.push(action.rowId);
        return {
            ...state,
            workflowColumns: copyCols4
    }
        case actionTypes.CHANGE_WORKFLOW_COLUMN_NAME:
            let copyCols2 = JSON.parse(JSON.stringify(state.workflowColumns));
            let col2Index = copyCols2.findIndex(col => col.id === action.id);
            copyCols2[col2Index].name = action.input;
        return {
            ...state,
            workflowColumns: copyCols2
            };
        case actionTypes.SAVE_WORKFLOW_COLUMN_NAME:
                let copyCols3 = JSON.parse(JSON.stringify(state.workflowColumns));
                let col3Index = copyCols3.findIndex(col => col.id === action.column.id);
                copyCols3[col3Index].name = action.column.name;
                copyCols3[col3Index].status_category = action.column.status_category;
                copyCols3[col3Index].is_waiting = action.column.is_waiting;
                copyCols3[col3Index].policy_description = action.column.policy_description;
                copyCols3[col3Index].low_wip = action.column.low_wip;
                copyCols3[col3Index].high_wip = action.column.high_wip;
               
        return {
            ...state,
            workflowColumns: copyCols3
            };

        default:
            return state;
          }
        }
        
        
        export default reducer;


        /*
 workflowColumns: [
        {
             id: 'column-1',
             title: 'Backlog',
             task_ids: [125, 126, 127]
         },
         {
             id: 'column-2',
             title: 'To do',
             task_ids: []
         },
          {
             id: 'column-3',
             title: 'Doing',
             task_ids: []
         },
          {
             id: 'column-4',
             title: 'Done',
             task_ids: []
         }
        ],
    workflowOrder: ['column-1', 'column-2', 'column-3', 'column-4']

        */