import * as actionTypes from '../actions/actionTypes';

const initialState = {
    products: [],
    product: {},
    loading: false,
    editResponse: null,
    addProductId: null,
    status: null,
    editStatusModal: false,
    editStatusModalType: '',
    itemTypeModal: false,
    itemTypeModalType: ''
}

const reducer = ( state = initialState, action ) => {

    switch (  action.type ) {
        case actionTypes.SET_PRODUCTS_DATA:

         return {
             ...state,
            products: action.products
            };
        case actionTypes.SET_SINGLE_PRODUCT_DATA:
            return {
                ...state,
            product: action.product
            };
        case actionTypes.DELETE_PRODUCTS_DATA:
            let products = JSON.parse(JSON.stringify(state.products));
            let newProducts = products.filter(pr => pr.hashid !== action.id);
        return {
            ...state,
            products: newProducts,
            product: {}
            };

        case actionTypes.EDIT_PRODUCTS_DATA:
        let editProducts = JSON.parse(JSON.stringify(state.products));
        let editIndex = editProducts.findIndex(pr => pr.hashid === action.id);
        editProducts[editIndex].name = action.name;
        editProducts[editIndex].description = action.desc;

        return {
        ...state,
        products: editProducts,
        editResponse: 200
        };

        case actionTypes.ADD_PRODUCT_DATA:
            let addProducts = JSON.parse(JSON.stringify(state.products));
            let newProduct = action.newProduct;
            addProducts.push(newProduct)
        return {
        ...state,
        products: addProducts,
        product: newProduct,
        addProductId: action.id,
      status: 200
        };
         case actionTypes.ADD_PRODUCT_ID:
        return {
            ...state,
            addProductId: null
            };

        case actionTypes.DATA_IMPORT_START:
            
        return {
            ...state,
            loading: true
            };
        
        case actionTypes.SAVE_IMPORT_PRODUCTS:

        let oldProducts = JSON.parse(JSON.stringify(state.products));
        let importProducts = oldProducts.concat(action.data)
    
        return {
        ...state,
        products: importProducts,
        loading: false
        };

        case actionTypes.ROWS_PER_PAGE:
            let displayRowsProduct = {...state.product};
            displayRowsProduct.user_options.display_rows = action.rowsPerPage;
    return {
        ...state,
        product: displayRowsProduct
        };
        case actionTypes.TEMPLATE_CHANGE:
            let copyProduct = {...state.product};
            copyProduct.options.templates = action.templateObject;
        return {
            ...state,
        product: copyProduct
            };

        case actionTypes.SIDEBAR_LOCK:
            let copyProductAll = {...state.product};
            copyProductAll.user_options = action.user_options;
    return {
        ...state,
      product: copyProductAll
        };

        case actionTypes.SIDEBAR_OPTION:
            let newSidebarOption = action.sidebarOption;
            let copyProduct6 = {...state.product};
            copyProduct6.user_options.sidebar_option = newSidebarOption;
        return {
            ...state,
                product: copyProduct6
            };

        case actionTypes.FRAME_CHANGE:
            let copyFrameProduct = {...state.product};
            copyFrameProduct.options.framework = action.options.framework;
            copyFrameProduct.options.framework_label = action.options.framework_label;
            copyFrameProduct.user_options.name_size = action.name_size;
        return {
            ...state,
                product: copyFrameProduct
            };

        case actionTypes.COLLAPSE_ROADMAP_COLUMN:
            let copyProduct2 = {...state.product};
               copyProduct2.user_options.collapse_columns = action.colArray;
            return {
                ...state,
                product: copyProduct2
                };
        case actionTypes.COLLAPSE_ROADMAP_ROW:
            let copyProduct3 = {...state.product};
            copyProduct3.user_options.collapse_rows = action.rowArray;
            return {
                ...state,
                product: copyProduct3
                };

        case actionTypes.COLLAPSE_GROUPS:
            let copyProduct10 = {...state.product};
            console.log()
            copyProduct10.user_options.collapse_groups = action.groupArray;
            return {
                ...state,
                product: copyProduct10
                };

        case actionTypes.TABLE_SWITCH:
            let copyProduct4 = {...state.product};
            copyProduct4.options.switch = action.tableSwitch;
            return {
                ...state,
                product: copyProduct4
            };
        
        case actionTypes.SAVE_NAME:
            let copyProduct5 = {...state.product};
            copyProduct5.user_options.name_size = action.name_size;
            return {
                ...state,
                product: copyProduct5
            };
        case actionTypes.TOGGLE_SIDEBAR:
            const copyProduct7 = {...state.product};
            copyProduct7.user_options.toggle_sidebar = action.toggle_sidebar;
            return {
                ...state,
                product: copyProduct7
            };
        case actionTypes.EDIT_STATUS_MODAL:

        return {
            ...state,
            editStatusModal: action.value,
            editStatusModalType: action.typeModal
        }
        case actionTypes.ITEM_TYPE_MODAL:

            return {
                ...state,
                itemTypeModal: action.value,
                itemTypeModalType: action.typeModal
            }

    default:
        return state;
    }
}


export default reducer;