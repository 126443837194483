import * as actionTypes from '../actions/actionTypes';
//import roadmapData from './Separates/columnsRoadmap';

const initialState = {
    rows: [],
    columns: [],
    fields: [],
    cards: [],
    status: false,
    add: false,
    dropDownCards: [],
    menuCardId: null,
    cardItems: []
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.SET_ROADMAP_DATA:
            let roadmapData = action.roadmapData;
            return {
                ...state,
                rows: roadmapData[0],
                columns: roadmapData[1],
                fields: roadmapData[2],
                cards: roadmapData[3],
                status: action.status
                };

            case actionTypes.CHANGE_ROADMAP_STATUS:
            return {
                ...state,
                status: false
                };

        case actionTypes.MOVE_ROADMAP_CARD:

            return {
                ...state,
                fields: action.data
                };
        case actionTypes.ADD_NEW_ROADMAP_ROW:
            let copyRows = JSON.parse(JSON.stringify(state.rows));
            let copyFields = JSON.parse(JSON.stringify(state.fields));
            let newActionFieds = action.fields;
                copyRows.push(action.row);
                let newFields = newActionFieds.concat(copyFields)
            return {
                ...state,
                rows: copyRows,
                fields: newFields
                };

        case actionTypes.ADD_NEW_ROADMAP_COLUMN:
            let copyCols = JSON.parse(JSON.stringify(state.columns));
            let copyFields2 = JSON.parse(JSON.stringify(state.fields));
            let newFields2 = copyFields2.concat(action.fields);
            copyCols.unshift(action.column);
            return {
                ...state,
                fields: newFields2,
                columns: copyCols
                };
        case actionTypes.ADD_NEW_ROADMAP_CARD:
            let copyCards2 = JSON.parse(JSON.stringify(state.cards));
            copyCards2.push(action.card);
            let copyFieldData = JSON.parse(JSON.stringify(state.fields));
            let field = copyFieldData.filter(fl => fl.id === action.field_id)[0];
            let flIndex = copyFieldData.indexOf(field);
            copyFieldData[flIndex].card_ids.push(action.card.id);

            return {
                ...state,
                cards: copyCards2,
                fields: copyFieldData,
                add: true
                };

        case actionTypes.CHANGE_ROADMAP_ROW_POSITION:
            let copyRows2 = JSON.parse(JSON.stringify(state.rows));
            let rowsArray = action.rowsArray;
            let firstNewRow = rowsArray[0];
            let secondNewRow = rowsArray[1];
            let firstOldRowIn = copyRows2.findIndex(row => row.id === secondNewRow.id);
            let secondOldRowIn = copyRows2.findIndex(row => row.id === firstNewRow.id);

            copyRows2[firstOldRowIn] = secondNewRow;
            copyRows2[secondOldRowIn] = firstNewRow;

        [copyRows2[firstOldRowIn], copyRows2[secondOldRowIn]] = [copyRows2[secondOldRowIn], copyRows2[firstOldRowIn]]
            
            return {
                ...state,
               rows: copyRows2
                };
        case actionTypes.CHANGE_ROADMAP_COLUMN_NAME:
            let copyCols2 = JSON.parse(JSON.stringify(state.columns));
            let col2Index = copyCols2.findIndex(col => col.id === action.id);
            copyCols2[col2Index].name = action.input;
        return {
            ...state,
            columns: copyCols2
            };
        case actionTypes.SAVE_ROADMAP_COLUMN_NAME:
                let copyCols3 = JSON.parse(JSON.stringify(state.columns));
                let col3Index = copyCols3.findIndex(col => col.id === action.column.id);
                copyCols3[col3Index].name = action.column.name;
                copyCols3[col3Index].status_category = action.column.status_category;
                copyCols3[col3Index].is_waiting = action.column.is_waiting;
                copyCols3[col3Index].policy_description = action.column.policy_description;
                copyCols3[col3Index].low_wip = action.column.low_wip;
                copyCols3[col3Index].high_wip = action.column.high_wip;
            return {
                ...state,
                columns: copyCols3
                };
        case actionTypes.DELETE_ROADMAP_COLUMN:
            let copyCols4 = JSON.parse(JSON.stringify(state.columns));
            let copyFields5 = JSON.parse(JSON.stringify(state.fields));
            let copyCards5 = JSON.parse(JSON.stringify(state.cards));
   
            copyCols4 = copyCols4.filter(col => col.id !== action.deleteCol.id);
            copyFields5 = copyFields5.filter(field => field.column_id !== action.deleteCol.id);
            copyCards5 = copyCards5.filter(card => card.column_id !== action.deleteCol.id);
            return {
                ...state,
                columns: copyCols4,
                fields: copyFields5,
                cards: copyCards5
                };

        case actionTypes.CHANGE_ROADMAP_ROW_NAME:
            let copyRows3 = JSON.parse(JSON.stringify(state.rows));
            let row2Index = copyRows3.findIndex(row => row.id === action.id);
            copyRows3[row2Index].name = action.input;
        return {
            ...state,
            rows: copyRows3
            };
        case actionTypes.SAVE_ROADMAP_ROW_NAME:
                let copyRows4 = JSON.parse(JSON.stringify(state.rows));
                let row3Index = copyRows4.findIndex(row => row.id === action.row.id);
                copyRows4[row3Index].name = action.row.name;
            return {
                ...state,
                rows: copyRows4,
                add: false
                };

        case actionTypes.DELETE_ROADMAP_ROW:
         //   let copyRows5 = JSON.parse(JSON.stringify(state.rows));
            let copyFields3 = JSON.parse(JSON.stringify(state.fields));
            let copyCards3 = JSON.parse(JSON.stringify(state.cards));
   
          //  copyRows5 = copyRows5.filter(row => row.id !== action.id);
            copyFields3 = copyFields3.filter(field => field.row_id !== action.id);
            copyCards3 = copyCards3.filter(card => card.row_id !== action.id);
            return {
                ...state,
                rows: action.rows,
                fields: copyFields3,
                cards: copyCards3
                };

        case actionTypes.CHANGE_ROADMAP_CARD_NAME:
            let copyCards4 = JSON.parse(JSON.stringify(state.cards));
            let card2Index = copyCards4.findIndex(card => card.id === action.id);
            copyCards4[card2Index].name = action.input;
        return {
            ...state,
            cards: copyCards4
            };
        case actionTypes.SAVE_ROADMAP_CARD_NAME:
                let copyCards6 = JSON.parse(JSON.stringify(state.cards));
                let card3Index = copyCards6.findIndex(card => card.id === action.card.id);
                copyCards6[card3Index].name = action.card.name;
                copyCards6[card3Index].is_blocked = action.card.is_blocked;
            return {
                ...state,
                cards: copyCards6
                };

        case actionTypes.DELETE_ROADMAP_CARD:
            let copyCards7 = JSON.parse(JSON.stringify(state.cards));
            copyCards7 = copyCards7.filter(card => card.id !== action.deleteCard.id);
            return {
                ...state,
                cards: copyCards7
                };
        case actionTypes.CARD_ITEMS:
        return {
            ...state,
            cardItems: action.cardItems,
            menuCardId: action.id
            };
            case actionTypes.SET_ROADMAP_CARDS:
                return {
                    ...state,
                    dropDownCards: action.cards,
                    status: action.status
                    };

        case actionTypes.SET_NEW_CARD_ASSIGNEE_DATA:
            let copyCards9 = JSON.parse(JSON.stringify(state.cards));
            let card9Index = copyCards9.findIndex(card => card.id === action.id);
            copyCards9[card9Index].assignees = action.assignees;
        return {
            ...state,
            cards: copyCards9
            };
            
        case actionTypes.CHANGE_ADD_ROADMAP_CARD:
        return {
            ...state,
            add: false
            };
        case actionTypes.UPDATE_ROADMAP_CARD:
            let copyCards10 = JSON.parse(JSON.stringify(state.cards));
            let card10Index = copyCards10.findIndex(card => card.id === action.id);
            copyCards10[card10Index] = action.card;
            return {
                ...state,
                cards: copyCards10
                };

        case actionTypes.CHANGE_CARD_ITEM_TYPE:
    
            let itemTypeData = JSON.parse(JSON.stringify(state.cards));
            let filterItemTypeData = itemTypeData.filter(dat => dat.id === action.id);
            let itemTypeDataIndex = itemTypeData.indexOf(filterItemTypeData[0])
            itemTypeData[itemTypeDataIndex].type = action.itemType;
            
            return {
                ...state,
                cards: itemTypeData
            };


        default:
            return state;
          }
        }
        
        
        export default reducer;