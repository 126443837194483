import * as actionTypes from '../actions/actionTypes';

const initialState = {
    inProgressData: [],
    status: false
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.SET_IN_PROGRESS_DATA:
            return {
                ...state,
                inProgressData: action.data,
                status: action.status
                };

        case actionTypes.CHANGE_IN_PROGRESS_STATUS:
            return {
                ...state,
                status: false
                };


default:
    return state;
    }
}


export default reducer;