import * as actionTypes from '../actions/actionTypes';

const initialState = {
    assigneeData: [],
    status: false,
    ids: []
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.SET_ASSIGNEE_DATA:
            return {
                ...state,
                assigneeData: action.data,
                status: action.status
                };

        case actionTypes.CHANGE_ASSIGNEE_STATUS:
            return {
                ...state,
                status: false
                };


default:
    return state;
    }
}


export default reducer;