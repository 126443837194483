import * as actionTypes from '../actions/actionTypes';

const initialState = {
    manageData: [],
    loading: false,
    inviteMsg: '',
    manageMsg: ''
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.ADD_INVITES_DATA:
            let invitesData = action.invitesData;
            let copyManageData = JSON.parse(JSON.stringify(state.manageData));
            let newData = invitesData.concat(copyManageData)

            return {
                ...state,
                manageData: newData,
                loading: false,
                inviteMsg: action.msg
                };

        case actionTypes.FETCH_INVITES_START:
            
            return {
                ...state,
                loading: true
                };

        case actionTypes.INVITES_ERROR:
            return {
                ...state,
                loading: false,
                inviteMsg: action.msg
                };

        case actionTypes.GET_MANAGE_DATA:
            let manageData = action.data.reverse();
            return {
                ...state,
                manageData: manageData
                };

        case actionTypes.DELETE_MANAGE_DATA:
            let copyManageData1 = JSON.parse(JSON.stringify(state.manageData));
            let newData1 = copyManageData1.filter(dat=> dat.id !== action.id);
           
            return {
                ...state,
                manageData: newData1,
                manageMsg: action.msg
                };
        case actionTypes.UPDATE_MANAGE_PERMISSION:
            let copyManageData2 = JSON.parse(JSON.stringify(state.manageData));
            let index = copyManageData2.findIndex(dat=> dat.id === action.object.id);
            copyManageData2[index].permission = action.object.permission;
            copyManageData2[index].permission_label = action.object.permission_label;
            
            return {
                ...state,
                manageData: copyManageData2,
                manageMsg: action.msg
                };
        case actionTypes.MANAGE_ERROR:
            return {
                ...state,
               // loading: false,
                manageMsg: action.msg
                };

        default:
            return state;
          }
        }
        
        
        export default reducer;