import { Component } from 'react';
import classes from './ErrorBoundary.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown} from '@fortawesome/free-solid-svg-icons';

class ErrorBoundary extends Component {
    constructor() {
        super();
        this.state = {hasError: false}
    }
    componentDidCatch() {  // ovaj metod hvata gresku kad nastanu u child komponenti
        this.setState({hasError: true})
    }
    reloadPage = () => {
        window.location.reload()
    }

    render() {
        if(this.state.hasError) {
            return <div className={classes.Error}>
                <p><FontAwesomeIcon icon={faFrown} /></p>
                <p>Sorry, something went wrong. Please try again!</p>
                <button className={classes.SignBttn} onClick={this.reloadPage}>Refresh the page</button>
                </div>
        }
        return this.props.children
    }
};

export default ErrorBoundary;