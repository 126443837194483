import * as actionTypes from '../actions/actionTypes';


const initialState = {
  business: '',
  product: '',
  personas: ''
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.GET_VISION_DATA:
            return {
                ...state,
                [action.typeVision]: action[action.typeVision]
                };

        case actionTypes.UPDATE_VISION_DATA:
            return {
                ...state,
                [action.typeVision]: action[action.typeVision]
                };

        default:
            return state;
          }
        }
        
        
        export default reducer;