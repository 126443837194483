import React, { Component, lazy, Suspense  } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import classes from './App.module.scss';
import ErrorBoundary from './components/ErrorBoundary';
//import axios from 'axios';
// import LandingPage from './containers/LandingPage/LandingPage';
// import AutomaticForecasting from './containers/LandingPage/AutomaticForecasting';
// import Careers from './containers/LandingPage/Careers';
// import AboutUs from './containers/LandingPage/AboutUs';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

// const LandingPage = lazy(() => import('./containers/LandingPage/LandingPage'));
// const AutomaticForecasting = lazy(() => import('./containers/LandingPage/AutomaticForecasting'));
// const KanbanTool = lazy(() => import('./containers/LandingPage/KanbanTool'));
// const Careers = lazy(() => import('./containers/LandingPage/Careers'));
// const AboutUs = lazy(() => import('./containers/LandingPage/AboutUs'));

const Forms = lazy(() => import('./containers/Forms/Forms'));
const Snackbar = lazy(() => import('./hoc/Snackbar/Snackbar'));

const Layout = lazy(() => import('./containers/Layout/Layout'));
// const Policy = lazy(() => import('./containers/LandingPage/Policy'));
// const Terms = lazy(() => import('./containers/LandingPage/Terms'));
// const ExpertBlog = lazy(() => import('./containers/LandingPage/ExpertBlog'));



class App extends Component {


  render() {

    if(process.env.REACT_APP_API_ENDPOINT === "https://api.prodgoal.com/api") {
      console.log = function no_console() {};
  }
  return (
    <ErrorBoundary>
    <div className={classes.App}>
      <Suspense fallback={<div>Loading.....</div>}>
      {/* <Route exact path="/" component={LandingPage} />
      <Route exact path="/free-agile-tool" component={LandingPage} />
      <Route exact path="/agile-kanban-forecasting" component={AutomaticForecasting} />
      <Route exact path="/free-online-kanban-tool" component={KanbanTool} />
      <Route exact path="/careers" component={Careers} />
      <Route exact path="/about-us" component={AboutUs} /> */}
              <Route exact path="/">
          <Redirect to="/signup" />
        </Route>
      <Route path="/login" render={(props) => <Forms type='login' title='Log into your account' {...props} /> } />
      <Route path="/forgot-password" render={(props) => <Forms type='forgot-password' title='Password recovery' {...props} /> } />
      <Route path="/new-password" render={(props) => <Forms type='new-password' title='Choose a new password' {...props} /> } />
      <Route path="/signup" render={(props) => <Forms type='signup' title='Sign up for your account' {...props} /> } />
      <Route path="/profile" render={(props) => <Forms type='profile' title='Complete your profile' {...props} /> } />
      <Route path="/verify" render={(props) => <Forms type='verify' title='Verify your email' {...props} /> } />

        <Route path="/home" component={Layout} />
        <Route exact path="/home/products">
          <Redirect to="/home/products/all" />
        </Route>
        {/* <Route exact path="/privacy-policy" component={ Policy} />
        <Route exact path="/terms-of-services" component={ Terms} />
        <Route path="/expert-blog" component={ ExpertBlog} /> */}
        <Snackbar />
        </Suspense>
    </div>
    </ErrorBoundary>
  );
}
}

export default withRouter(App);


//what-is-kanban-board-how-kanban-board-works-why-use-it-is-it-free|
