import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    msgName: '',
    msgCod: '',
    msgDur: '',
    addType: '',
    addRowId: null,
    id: null,
    edit: false,

    loading: false,
    framework: {
        name: 'no-framework',
        label: 'No framework',
        id: 1
    },
    statusRequest: false
}

const reducer = ( state = initialState, action ) => {

    switch (  action.type ) {

        case actionTypes.SET_DATA:

            let fw = action.framework;
            if(Object.keys(fw).length === 0 || action.framework === null) {
                fw = state.framework;
            }
         return {
             ...state,
                data: action.data,
                framework: fw,
                loading: false
            };
        case actionTypes.SET_ROWS:

            return {
                ...state,
                data: action.rows,
            };

        case actionTypes.DELETE_DATA:
            let newData = state.data.filter(dat => {
                return action.id !== dat.id
              });
         return {
             ...state,
                data: newData,
                msgName: ''
            };
        case actionTypes.FETCH_NEW_ROW:
            let objId = action.obj.id;
            let copyStateData = JSON.parse(JSON.stringify(state.data));
           copyStateData.push(action.obj);
            
         return {
            ...state,
            data: copyStateData,
            addRowId: objId,
            addType: action.addType
                };

        case actionTypes.CHANGE_NAME:
            let copyData = JSON.parse(JSON.stringify(state.data));
            let index = copyData.map((row) => {return row.id}).indexOf(action.id);
            let newInput = action.event;
            let newMsg = '';
            let id = action.id;
            copyData[index].name = newInput;
        
      
        if(newInput.trim() === '') {
            newMsg = '*Required.';
        }
        if(newInput.length > 191) {
            newMsg = 'Name is too long.';
        }
         return {
            ...state,
            data: copyData,
            msgName: newMsg,
            addType: action.addType,
            id: id,
            edit: true
                };

        case actionTypes.SAVE_NAME_CHANGE:
            let copy = JSON.parse(JSON.stringify(state.data));
            let indexSave = copy.findIndex(x => x.id === action.object.id)
            copy[indexSave].name = action.object.name;
            copy[indexSave].is_blocked = action.object.is_blocked;
    
         return {
            ...state,
            data: copy,
            loading: false,
            msgName: '',
            id: action.id,
            addType: action.addType,
            edit: false,
            addRowId: null
            };
        case actionTypes.CHANGE_NAME_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.UPDATE_TABLE_VALUE:
            const copyData1 = JSON.parse(JSON.stringify(state.data));
            let rowIndex = copyData1.findIndex(x => x.id === action.object.id);
            if(action.val === 'duration') {
                copyData1[rowIndex].fw_cols.duration = action.object.fw_cols.duration;
                copyData1[rowIndex].fw_cols.result = action.object.fw_cols.result;
            } else if (action.val === 'cod') {
                copyData1[rowIndex].fw_cols.cost_of_delay = action.object.fw_cols.cost_of_delay;
                copyData1[rowIndex].fw_cols.result = action.object.fw_cols.result;
            } else {
                copyData1[rowIndex].fw_cols = action.object.fw_cols;
            }

            return {
                ...state,
                data: copyData1,
                loading: false,
                msgName: '',
                addType: action.addType
            };

        case actionTypes.DROP_DATA:
            let dropData = action.dropData;
            let idArray = dropData.map(dat => dat.id);
    
            let oldData = JSON.parse(JSON.stringify(state.data));
            oldData.sort(function(a, b){  
                return idArray.indexOf(a.table_row_info_id) - idArray.indexOf(b.table_row_info_id);
              });
           
            return {
                ...state,
                data: oldData
            };

        case actionTypes.CHANGE_ROW_STATUS:
            
            let statusData = JSON.parse(JSON.stringify(state.data));
            let filterStatusData = statusData.filter(dat => dat.id === action.id);
            let statusDataIndex = statusData.indexOf(filterStatusData[0])
            statusData[statusDataIndex].status = action.rowStatus;
            statusData[statusDataIndex].days_in_progress = action.days_in_progress
            
            return {
                ...state,
                data: statusData
            };
        case actionTypes.CHANGE_ROW_ROADMAP:
            
            let roadmapData = JSON.parse(JSON.stringify(state.data));
            let filterRoadmapData = roadmapData.filter(dat => dat.id === action.id);
            let roadmapDataIndex = roadmapData.indexOf(filterRoadmapData[0])
            roadmapData[roadmapDataIndex].card_id = action.card_id;
            roadmapData[roadmapDataIndex].roadmap = action.roadmap;
            
            return {
                ...state,
                data: roadmapData
            };

            case actionTypes.CHANGE_ROW_ITEM_TYPE:
            
                let itemTypeData = JSON.parse(JSON.stringify(state.data));
                let filterItemTypeData = itemTypeData.filter(dat => dat.id === action.id);
                let itemTypeDataIndex = itemTypeData.indexOf(filterItemTypeData[0])
                itemTypeData[itemTypeDataIndex].type = action.itemType;
                
                return {
                    ...state,
                    data: itemTypeData
                };
       

        case actionTypes.CHANGE_COD_VAL:
            let copyCodData = JSON.parse(JSON.stringify(state.data));
            let indexCod = copyCodData.map((row) => {return row.id}).indexOf(action.id);
            let newCodInput = action.event;
            let msgCod = '';
            let idCod = action.id;
            copyCodData[indexCod].fw_cols.cost_of_delay = newCodInput;


            if (!Number.isInteger(Number(newCodInput))) {
            msgCod = 'Value must be integer.'
            }
            if(isNaN(newCodInput)) {
                msgCod = 'Value must be numeric.'
            }
            if(newCodInput === '') {
                msgCod = '*Required.';
            }
            if(Number(newCodInput) < 0) {
                msgCod = 'Value must be positive.'
            }
            if(newCodInput.length > 16) {
                msgCod = 'Value is too long.';
            }

            return {
            ...state,
            data: copyCodData,
            msgCod: msgCod,
            addType: action.addType,
            id: idCod,
            edit: true
                };
        case actionTypes.CHANGE_DUR_VAL:
            let copyDurData = JSON.parse(JSON.stringify(state.data));
            // let splicedData = JSON.parse(JSON.stringify(state.cd3Data));
            let indexDur = copyDurData.map((row) => {return row.id}).indexOf(action.id);
            let newDurInput = action.event;
            let msgDur = '';
            let idDur = action.id;
            copyDurData[indexDur].fw_cols.duration = newDurInput;

            if(isNaN(newDurInput)) {
                msgDur = 'Value must be numeric.'
            }
            if(newDurInput === '') {
                msgDur = '*Required.';
            }
            if(Number(newDurInput) < 0) {
                msgDur = 'Value must be positive.'
            }
            if(newDurInput.length > 8) {
                msgDur = 'Value is too long.';
            }
            let countDurDecimals = function (value) { 
            return value % 1?value.toString().split(".")[1].length:0; 
            }
        if(countDurDecimals(newDurInput) > 1) {
            msgDur = 'Value can have only one decimal.'
        } 
            return {
            ...state,
            data: copyDurData,
            msgDur: msgDur,
            addType: action.addType,
            id: idDur,
            edit: true
                };


        case actionTypes.CHANGE_FRAMEWORK:
            let framework = JSON.parse(JSON.stringify(state.framework));
            framework.label = action.framework.label;
            framework.name = action.framework.name;
            framework.id = action.framework.id;
            return {
            ...state,
                data: action.cols,
                framework: framework
            };

        case actionTypes.SET_NEW_ROW_ASSIGNEE_DATA:
            let assigneeData = JSON.parse(JSON.stringify(state.data));
            let filterAssigneeData = assigneeData.filter(dat => dat.id === action.id);
            let assigneeDataIndex = assigneeData.indexOf(filterAssigneeData[0])
            assigneeData[assigneeDataIndex].assignees = action.assignees;
            return {
                ...state,
                data: assigneeData
                };
        

    default:
        return state;
    }
}


export default reducer;