import * as actionTypes from '../actions/actionTypes';


const initialState = {
  itemTypes: [],
  statusRequest: false,
  itemTypesCategories: [],
  statusRequestCategories: false
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.ITEM_TYPES:
            return {
                ...state,
                itemTypes: action.data,
                statusRequest: true
                };

        case actionTypes.ITEM_TYPES_STATUS:
            return {
                ...state,
                statusRequest: false
                };

        case actionTypes.ITEM_TYPES_CATEGORIES:
          return {
              ...state,
              itemTypesCategories: action.data,
              statusRequest: true
              };
        case actionTypes.ITEM_TYPES_CATEGORY_STATUS:
          return {
              ...state,
              statusRequestCategories: action.status
              };

        case actionTypes.CHANGE_ITEM_TYPE_CATEGORY:
          let copyItemTypes = JSON.parse(JSON.stringify(state.itemTypes));
          let filterItem = copyItemTypes.filter(item => item.id === action.itemType.id)[0];
          let index = copyItemTypes.indexOf(filterItem);
          copyItemTypes[index].item_type_category_id = action.itemType.item_type_category_id;
          console.log(copyItemTypes)
          return {
            ...state,
            itemTypes: copyItemTypes
          };
        case actionTypes.DELETE_ITEM_TYPE:
          let copyItemTypes1 = JSON.parse(JSON.stringify(state.itemTypes));
          let filterItems = copyItemTypes1.filter(item => item.id !== action.id);
          console.log(filterItems)
          return {
            ...state,
            itemTypes: filterItems
          };
        case actionTypes.ADD_ITEM_TYPE:
          let copyItemTypes2 = JSON.parse(JSON.stringify(state.itemTypes));
          copyItemTypes2.push(action.itemType);
          return {
            ...state,
            itemTypes: copyItemTypes2
          };
        case actionTypes.ITEM_TYPE_NAME:
          let copyItemTypes3 = JSON.parse(JSON.stringify(state.itemTypes));
          let filterItem3 = copyItemTypes3.filter(item => item.id === action.itemType.id)[0];
          let index3 = copyItemTypes3.indexOf(filterItem3);
          copyItemTypes3[index3].name = action.itemType.name;
          return {
            ...state,
            itemTypes: copyItemTypes3
          };
          case actionTypes.ITEM_TYPE_CATEGORY_NAME:
            console.log(action.typeCategory)
            let copyItemTypesCategories = JSON.parse(JSON.stringify(state.itemTypesCategories));
            let filterItemCategory = copyItemTypesCategories.filter(item => item.id === action.typeCategory.id)[0];
            let index4 = copyItemTypesCategories.indexOf(filterItemCategory);
            console.log(copyItemTypesCategories[index4])
            copyItemTypesCategories[index4].name = action.typeCategory.name;
            return {
              ...state,
              itemTypesCategories: copyItemTypesCategories
            };

        default:
            return state;
          }
        }
        
        
        export default reducer;