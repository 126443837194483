import * as actionTypes from '../actions/actionTypes';


const initialState = {
    columnData: [],
    columnOrder: [],
    columnHeaders: {},
    frameworks: [],
    loading: false,
    statusRequest: false
}

const reducer = ( state = initialState, action ) => {
    switch (  action.type ) {

        case actionTypes.GET_GROUPS:
            return {
                ...state,
                columnData: action.data,
                columnOrder: action.order,
                columnHeaders: action.headers,
                loading: false
        }
        case actionTypes.SET_LOADER:
 
            return {
                ...state,
                loading: true     
        }
        case actionTypes.SET_ROW_IN_TABLE_GROUP:
 
            return {
                ...state,
                columnData: action.data 
        }

        case actionTypes.GET_NEW_GROUP:
            let copyGroups = JSON.parse(JSON.stringify(state.columnData));
            let copyOrder = JSON.parse(JSON.stringify(state.columnOrder));
            copyGroups.push(action.data)
            copyOrder.push(action.data.unique_id)
 
            return {
                ...state,
                columnData: copyGroups,
                columnOrder: copyOrder
        }
        case actionTypes.GET_NEW_GROUP_FROM_BOARD:
            let copyGroups1 = JSON.parse(JSON.stringify(state.columnData));
            let copyOrder1 = JSON.parse(JSON.stringify(state.columnOrder));
            copyGroups1.push(action.data)
            copyOrder1.push(action.data.unique_id)
 
            return {
                ...state,
                columnData: copyGroups1,
                columnOrder: copyOrder1,
                columnHeaders: action.headers  
        }

        case actionTypes.MOVE_GROUP_ROW:
 
            return {
                ...state,
                columnData: action.data     
        }
        case actionTypes.MOVE_GROUP_ORDER:
 
            return {
                ...state,
                columnOrder: action.data     
        }

        case actionTypes.UPDATE_GROUPS:
           
        return {
            ...state,
            columnData: action.groups
    }

    case actionTypes.SET_FRAMEWORKS:
        return {
            ...state,
            frameworks: action.fw,
            statusRequest: action.status
    };
    case actionTypes.CHANGE_FRAMEWORK_REQUEST_STATUS:
        return {
            ...state,
            statusRequest: false
            };
    case actionTypes.CHANGE_FRAMEWORK_HEADERS:
        const copyHeaders1 = JSON.parse(JSON.stringify(state.columnHeaders));
        copyHeaders1.fw_headers = action.headers;
        return {
            ...state,
            columnHeaders: copyHeaders1
    }

    case actionTypes.SORT_COLUMN_DATA:
        let copyData2 = JSON.parse(JSON.stringify(state.columnData));
        let sortGroupIndex = copyData2.findIndex(gr => gr.id === action.group_id);
        copyData2[sortGroupIndex].row_ids = action.row_ids;
        copyData2[sortGroupIndex].sort = action.sort;
        
        return {
        ...state,
        columnData: copyData2
        };

    case actionTypes.CHANGE_GROUP_NAME:
            let copyCols2 = JSON.parse(JSON.stringify(state.columnData));
            let col2Index = copyCols2.findIndex(col => col.id === action.id);
            copyCols2[col2Index].name = action.input;
        return {
            ...state,
            columnData: copyCols2
            };
    case actionTypes.SAVE_GROUP_NAME:
                let copyCols3 = JSON.parse(JSON.stringify(state.columnData));
                let col3Index = copyCols3.findIndex(col => col.id === action.column.id);
                copyCols3[col3Index].name = action.column.name;
        return {
            ...state,
            columnData: copyCols3
            };

        default:
            return state;
          }
        }
        
        
        export default reducer;

        /* CD3
fw_headers: [
                    {name: 'Item name', id: 'name', info: null},
            {name: 'Cost of Delay ($/wk)', id: 'cost_of_delay', info: 'The total amount of cost for delaying this opportunity for a week. It can be calculated by taking into account how much this would be worth to us if we had it today and then how much we lose each week for not having it.'},
            {name: 'Duration (wks)', id: 'duration', info: 'The number of weeks needed to finish the opportunity. '},
            {name: 'WSJF', id: 'result', info: 'WSJF (CD3) Score = Cost of Delay / Duration. Higher the score, higher the priority and economic benefits.'}
                    ]

    SAFE

    [
        {name: 'Item name', id: 'opportunity_name', info: null},
        {name: 'User Business Value', id: 'user_business_value', info: 'The sum of total value this opportunity will bring to users and/or business.'},
        {name: 'RR | OE', id: 'rr_oe', info: 'The sum of total value of this opportunity reducing risks and/or enabling new business opportunities.'},
        {name: 'Time Criticality', id: 'time_criticality', info: 'The sum of time criticality factors that an opportunity has (value decay, fixed deadline, market race, other urgencies).'},
        {name: 'Job Size', id: 'job_size', info: 'A proxy variable for job duration.'},
        {name: 'WSJF', id: 'result', info: 'SAFe WSJF Score = (User-Business Value + Time Criticality + Risk Reduction & Opportunity Enablement) / Job Size. Higher the score, higher the priority and economic benefits.'}
    ]   
        */




    /*
    columnData: [

        {
            id: 'group-1',
            title: 'Group 1',
            row_ids: [1, 2]
        },
        {
            id: 'group-2',
            title: 'Group 2',
            row_ids: []
        },
         {
            id: 'group-3',
            title: 'Group 3',
            row_ids: []
        },
         {
            id: 'group-4',
            title: 'Group 4',
            row_ids: []
        }

    ],
    columnOrder: ['group-1', 'group-2', 'group-3', 'group-4'],
    columnHeaders: {
 
                name: {name: 'Name item', id: 'name', info: 'Name'},
                roadmap: {name: 'Roadmap', id: 'roadmap', info: 'Roadmap'},
                status: {name: 'Status', id: 'status', info: 'Status'},
                fw_headers: [
                    {name: 'Value', id: 'value', info: 'Value'},
                    {name: 'Urgency', id: 'urgency', info: 'Urgency'},
                    {name: 'Cost of Delay', id: 'cost_of_delay', info: 'Cod'},
                    {name: 'Size', id: 'size', info: 'Job Size'},
                    {name: 'WSJF Order', id: 'wsjf_order', info: 'Wsjf'}
                    ]
                }

                */