export const RESET_APP = 'RESET_APP';


// TABLE-DATA

export const SET_DATA = 'SET_DATA';

export const SET_ROWS = 'SET_ROWS';

export const DELETE_DATA = 'DELETE_DATA';

export const FETCH_NEW_ROW = 'FETCH_NEW_ROW';

export const CHANGE_NAME = 'CHANGE_NAME';

export const SAVE_NAME_CHANGE = 'SAVE_NAME_CHANGE';

export const CHANGE_NAME_START = 'CHANGE_NAME_START';

export const DROP_DATA = 'DROP_DATA';

export const CHANGE_ROW_STATUS = 'CHANGE_ROW_STATUS';

export const CHANGE_ROW_ROADMAP = 'CHANGE_ROW_ROADMAP';

export const CHANGE_ROW_ITEM_TYPE = 'CHANGE_ROW_ITEM_TYPE';

export const ITEM_MODAL = 'ITEM_MODAL'; 

export const POST_ITEM_MODAL = 'POST_ITEM_MODAL';

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const CHANGE_TEXTAREA = 'CHANGE_TEXTAREA';

export const ADD_PRODUCT_ID = 'ADD_PRODUCT_ID';

export const CHANGE_FRAMEWORK = 'CHANGE_FRAMEWORK';

export const UPDATE_TABLE_VALUE = 'UPDATE_TABLE_VALUE';

export const CHANGE_WORKFLOW_COLUMNS_DATA = 'CHANGE_WORKFLOW_COLUMNS_DATA';


//cd3

export const CHANGE_COD_VAL = 'CHANGE_COD_VAL';

export const CHANGE_DUR_VAL = 'CHANGE_DUR_VAL';


//safe

export const CHANGE_SAFE_VAL = 'CHANGE_SAFE_VAL';

//PRODUCTS

export const SET_PRODUCTS_DATA = 'SET_PRODUCTS_DATA';

export const SET_SINGLE_PRODUCT_DATA = 'SET_SINGLE_PRODUCT_DATA';

export const DELETE_PRODUCTS_DATA = 'DELETE_PRODUCTS_DATA';

export const EDIT_PRODUCTS_DATA = 'EDIT_PRODUCTS_DATA';

export const ADD_PRODUCT_DATA = 'ADD_PRODUCT_DATA';

export const DATA_IMPORT_START = 'DATA_IMPORT_START';

export const SAVE_IMPORT_PRODUCTS = 'SAVE_IMPORT_PRODUCTS';

export const ROWS_PER_PAGE = 'ROWS_PER_PAGE';

export const TEMPLATE_CHANGE = 'TEMPLATE_CHANGE';

export const SIDEBAR_LOCK = 'SIDEBAR_LOCK';

export const SIDEBAR_OPTION = 'SIDEBAR_OPTION';

export const FRAME_CHANGE = 'FRAME_CHANGE';

export const COLLAPSE_ROADMAP_COLUMN = 'COLLAPSE_ROADMAP_COLUMN';

export const COLLAPSE_ROADMAP_ROW = 'COLLAPSE_ROADMAP_ROW';

export const TABLE_SWITCH = 'TABLE_SWITCH';

export const SAVE_NAME = 'SAVE_NAME';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const TOGGLE_SIDEBAR_BUTTON = 'TOGGLE_SIDEBAR_BUTTON';

export const COLLAPSE_GROUPS = 'COLLAPSE_GROUPS';

export const EDIT_STATUS_MODAL = 'EDIT_STATUS_MODAL';

export const ITEM_TYPE_MODAL = 'ITEM_TYPE_MODAL';


// WORKFLOW 

export const GET_WORKFLOW_COLUMNS = 'GET_WORKFLOW_COLUMNS';

export const MOVE_WORKFLOW_TASK = 'MOVE_WORKFLOW_TASK';

export const MOVE_WORKFLOW_ORDER = 'MOVE_WORKFLOW_ORDER';

export const GET_NEW_WORKFLOW_COLUMN = 'GET_NEW_WORKFLOW_COLUMN';

export const DELETE_WORKFLOW_COLUMN = "DELETE_WORKFLOW_COLUMN";

export const CHANGE_WORKFLOW_COLUMN_NAME = 'CHANGE_WORKFLOW_COLUMN_NAME';

export const SAVE_WORKFLOW_COLUMN_NAME = 'SAVE_WORKFLOW_COLUMN_NAME';

export const CHANGE_WORKFLOW_STATUS = 'CHANGE_WORKFLOW_STATUS';

export const ADD_ROW_ID_TO_WORKFLOW_COLUMN = 'ADD_ROW_ID_TO_WORKFLOW_COLUMN';

export const SET_ROW_IN_TABLE_GROUP = 'SET_ROW_IN_TABLE_GROUP';


// TABLE-GROUPS

export const UPDATE_GROUPS = 'UPDATE_GROUPS';

export const GET_GROUPS = 'GET_GROUPS';

export const GET_NEW_GROUP = 'GET_NEW_GROUP';

export const GET_NEW_GROUP_FROM_BOARD = 'GET_NEW_GROUP_FROM_BOARD';

export const MOVE_GROUP_ROW = 'MOVE_GROUP_ROW';

export const MOVE_GROUP_ORDER = 'MOVE_GROUP_ORDER';

export const CHANGE_FRAMEWORK_HEADERS = 'CHANGE_FRAMEWORK_HEADERS';

export const SET_FRAMEWORKS = 'SET_FRAMEWORKS';

export const SORT_COLUMN_DATA = 'SORT_COLUMN_DATA';

export const CHANGE_GROUP_NAME = 'CHANGE_GROUP_NAME';

export const SAVE_GROUP_NAME = 'SAVE_GROUP_NAME';

export const SET_LOADER = 'SET_LOADER';

export const CHANGE_FRAMEWORK_REQUEST_STATUS = 'CHANGE_FRAMEWORK_REQUEST_STATUS';


// ROADMAP

export const MOVE_ROADMAP_CARD = 'MOVE_ROADMAP_CARD';

export const ADD_NEW_ROADMAP_ROW = 'ADD_NEW_ROADMAP_ROW';

export const ADD_NEW_ROADMAP_COLUMN = 'ADD_NEW_ROADMAP_COLUMN';

export const ADD_NEW_ROADMAP_CARD = 'ADD_NEW_ROADMAP_CARD';

export const CHANGE_ROADMAP_ROW_POSITION = 'CHANGE_ROADMAP_ROW_POSITION';

export const SET_ROADMAP_DATA = 'SET_ROADMAP_DATA';

export const DELETE_ROADMAP_ROW = 'DELETE_ROADMAP_ROW';

export const SAVE_ROADMAP_COLUMN_NAME = 'SAVE_ROADMAP_COLUMN_NAME';

export const CHANGE_ROADMAP_COLUMN_NAME = 'CHANGE_ROADMAP_COLUMN_NAME';

export const DELETE_ROADMAP_COLUMN = 'DELETE_ROADMAP_COLUMN';

export const SAVE_ROADMAP_ROW_NAME = 'SAVE_ROADMAP_ROW_NAME';

export const CHANGE_ROADMAP_ROW_NAME = 'CHANGE_ROADMAP_ROW_NAME';

export const SAVE_ROADMAP_CARD_NAME = 'SAVE_ROADMAP_CARD_NAME';

export const CHANGE_ROADMAP_CARD_NAME = 'CHANGE_ROADMAP_CARD_NAME';

export const DELETE_ROADMAP_CARD = 'DELETE_ROADMAP_CARD';

export const CARD_ITEMS = 'CARD_ITEMS';

export const CHANGE_ROADMAP_STATUS = 'CHANGE_ROADMAP_STATUS';

export const CHANGE_ADD_ROADMAP_CARD = 'CHANGE_ADD_ROADMAP_CARD';

export const UPDATE_ROADMAP_CARD = 'UPDATE_ROADMAP_CARD';

export const SET_ROADMAP_CARDS = 'SET_ROADMAP_CARDS';

export const CHANGE_CARD_ITEM_TYPE = 'CHANGE_CARD_ITEM_TYPE';

// VISION

export const UPDATE_VISION_DATA = 'UPDATE_VISION_DATA';

export const GET_VISION_DATA = 'GET_VISION_DATA';


// PEOPLE

export const GET_MANAGE_DATA = 'GET_MANAGE_DATA';

export const ADD_INVITES_DATA = 'ADD_INVITES_DATA';

export const DELETE_MANAGE_DATA = 'DELETE_MANAGE_DATA';

export const FETCH_INVITES_START = 'FETCH_INVITES_START';

export const INVITES_ERROR = 'INVITES_ERROR';

export const UPDATE_MANAGE_PERMISSION = 'UPDATE_MANAGE_PERMISSION';

export const MANAGE_ERROR = 'MANAGE_ERROR';

// CHART DATA

export const GET_COD_CHART_COLUMNS = 'GET_COD_CHART_COLUMNS';

export const REPLACE_COD_CHART_COLUMN_DATA = 'REPLACE_COD_CHART_COLUMN_DATA';

export const GET_WSJF_CHART_COLUMNS = 'GET_WSJF_CHART_COLUMNS';

export const REPLACE_WSJF_CHART_COLUMN_DATA = 'REPLACE_WSJF_CHART_COLUMN_DATA';

export const ADD_ROW_ID_TO_COD_CHART_COLUMN = 'ADD_ROW_ID_TO_COD_CHART_COLUMN';

export const ADD_ROW_ID_TO_WSJF_CHART_COLUMN = 'ADD_ROW_ID_TO_WSJF_CHART_COLUMN';

export const CHART_CHANGE = 'CHART_CHANGE';

export const CHANGE_COD_CHART_COLUMNS = 'CHANGE_COD_CHART_COLUMNS';

export const CHANGE_WSJF_CHART_COLUMNS = 'CHANGE_WSJF_CHART_COLUMNS';


// ASSIGNEE

export const SET_ASSIGNEE_DATA = 'SET_ASSIGNEE_DATA';

export const SET_NEW_ROW_ASSIGNEE_DATA = 'SET_NEW_ROW_ASSIGNEE_DATA';

export const SET_NEW_CARD_ASSIGNEE_DATA = 'SET_NEW_CARD_ASSIGNEE_DATA';

export const CHANGE_ASSIGNEE_STATUS = 'CHANGE_ASSIGNEE_STATUS';

// IN PROGRESS

export const SET_IN_PROGRESS_DATA = 'SET_IN_PROGRESS_DATA';

export const CHANGE_IN_PROGRESS_STATUS = 'SET_IN_PROGRESS_DATA';

// ITEM TYPES

export const ITEM_TYPES = 'ITEM_TYPES';

export const ITEM_TYPES_STATUS = 'ITEM_TYPES_STATUS';

export const ITEM_TYPES_CATEGORIES = 'ITEM_TYPES_CATEGORIES';

export const ITEM_TYPES_CATEGORY_STATUS = 'ITEM_TYPES_CATEGORY_STATUS';

export const CHANGE_ITEM_TYPE_CATEGORY = 'CHANGE_ITEM_TYPE_CATEGORY';

export const DELETE_ITEM_TYPE = 'DELETE_ITEM_TYPE';

export const ADD_ITEM_TYPE = 'ADD_ITEM_TYPE';

export const ITEM_TYPE_NAME = 'ITEM_TYPE_NAME';

export const ITEM_TYPE_CATEGORY_NAME = 'ITEM_TYPE_CATEGORY_NAME';