import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
//import { initAmplitude } from './utilities/amplitude';
import chartDataReducer from './store/reducers/chartData';
import productsReducer from './store/reducers/products';
import workflowReducer from './store/reducers/workflow';
import roadmapReducer from './store/reducers/roadmap';
import itemModalReducer from './store/reducers/itemModal';
import visionReducer from './store/reducers/vision';
import peopleReducer from './store/reducers/people';
import tableDataReducer from './store/reducers/table_data';
import tableGroupsReducer from './store/reducers/table_groups';
import assigneeReducer from './store/reducers/assignee';
import inProgressReducer from './store/reducers/inProgress';
import itemTypeReducer from "./store/reducers/itemType";

import './index.module.scss';
import App from './App.js';
//import * as serviceWorker from './serviceWorker';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allReducers = combineReducers({
    columnData: chartDataReducer,
    products: productsReducer,
    workflow: workflowReducer,
    roadmap: roadmapReducer,
    itemModal: itemModalReducer,
    vision: visionReducer,
    people: peopleReducer,
    table_data: tableDataReducer,
    table_groups: tableGroupsReducer,
    assignee: assigneeReducer,
    inProgress: inProgressReducer,
    itemType: itemTypeReducer
});
const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
      }
    return allReducers(state, action);
  }

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
    )); 


const app = (
    <Provider store={store}>
        <BrowserRouter basename="/">
    <App />
    </BrowserRouter>
    </Provider>
);

//initAmplitude();

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();