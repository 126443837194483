import * as actionTypes from '../actions/actionTypes';
//import columnData from './Separates/columnsMatrix';

const initialState = {
    chart: {},
    codChartColumnData: [],
    wsjfChartColumnData: []
}

const reducer = ( state = initialState, action ) => {

    switch (  action.type ) {

        case actionTypes.GET_COD_CHART_COLUMNS:
            return {
                ...state,
               codChartColumnData: action.codChartColumnData
                };

        case actionTypes.REPLACE_COD_CHART_COLUMN_DATA:
            return {
                ...state,
                codChartColumnData: action.data
                };

        case actionTypes.ADD_ROW_ID_TO_COD_CHART_COLUMN:
            let data = JSON.parse(JSON.stringify(state.codChartColumnData));
            if(data.length !== 0) {
                data[9].task_ids.unshift(action.rowId);
            }
            return {
                ...state,
                codChartColumnData: data
                };

        case actionTypes.GET_WSJF_CHART_COLUMNS:
            return {
                ...state,
                wsjfChartColumnData: action.wsjfChartColumnData
                };
        case actionTypes.REPLACE_WSJF_CHART_COLUMN_DATA:
            return {
                ...state,
                wsjfChartColumnData: action.wsjfChartColumnData
                };
        case actionTypes.ADD_ROW_ID_TO_WSJF_CHART_COLUMN:
            let data3 = JSON.parse(JSON.stringify(state.wsjfChartColumnData));
            if(data3.length !== 0) {
                data3[25].task_ids.unshift(action.rowId);
            }
            return {
                ...state,
                wsjfChartColumnData: data3
                };

        case actionTypes.CHART_CHANGE:
            return {
                ...state,
                chart: action.chart
                };
        case actionTypes.CHANGE_COD_CHART_COLUMNS:
            let data1 = JSON.parse(JSON.stringify(state.codChartColumnData));
            let startCol = data1.filter(dat => dat.task_ids.includes(action.rowId))[0];
            const startColIndex = data1.indexOf(startCol);
            const indexRowId = startCol.task_ids.indexOf(action.rowId);
            startCol.task_ids.splice(indexRowId, 1);

            let finishCol = data1.filter(dat => dat.cost_of_delay === action.fw_cols.cost_of_delay && 
                dat.urgency === action.fw_cols.urgency && 
                dat.value === action.fw_cols.value)[0];
                if(finishCol === undefined) {
                    finishCol = data1[9];
                }
            const finishColIndex = data1.indexOf(finishCol);
            finishCol.task_ids.splice(0, 0, action.rowId);

            data1[startColIndex] = startCol;
            data1[finishColIndex] = finishCol;

            return {
                ...state,
                codChartColumnData: data1
                };
        case actionTypes.CHANGE_WSJF_CHART_COLUMNS:
            let data2 = JSON.parse(JSON.stringify(state.wsjfChartColumnData));
            let startCol2 = data2.filter(dat => dat.task_ids.includes(action.rowId))[0];
            const startColIndex2 = data2.indexOf(startCol2);
            const indexRowId2 = startCol2.task_ids.indexOf(action.rowId);
            startCol2.task_ids.splice(indexRowId2, 1);

            let finishCol2 = data2.filter(dat => dat.cost_of_delay === action.fw_cols.cost_of_delay && 
                dat.size === action.fw_cols.size)[0];
                if(finishCol2 === undefined) {
                    finishCol2 = data2[25];
                }
                const finishColIndex2 = data2.indexOf(finishCol2);
                finishCol2.task_ids.splice(0, 0, action.rowId);

            data2[startColIndex2] = startCol2;
            data2[finishColIndex2] = finishCol2;
            return {
                ...state,
                wsjfChartColumnData: data2
                };
        default:
            return state;
          }
        }
        
        
        export default reducer;