import * as actionTypes from '../actions/actionTypes';

const initialState = {
    modalObject: {
        description: ''
    },
    modalId: null
}

const reducer = ( state = initialState, action ) => {

    switch (  action.type ) {

        case actionTypes.ITEM_MODAL:

        return {
            ...state,
            modalObject: action.object,
            modalId: action.id
        };
        case actionTypes.POST_ITEM_MODAL:
            let postModalObjectCopy = {...state.modalObject};
            postModalObjectCopy.description = action.value;
           
        return {
            ...state,
            modalObject: postModalObjectCopy
        };
        case actionTypes.CLOSE_MODAL:
        return {
            ...state,
            modalObject: {}
        };

        case actionTypes.CHANGE_TEXTAREA:
            let modalObjectCopy = {...state.modalObject};
            modalObjectCopy.description = action.input;
            return {
                ...state,
                modalObject: modalObjectCopy 
            };
    default:
        return state;
    }
}


export default reducer;